import React from 'react'
import Homebutton from './Components/homebutton'
import HomeLink  from './Components/homelink'

const About = () => {

    return (
        <div>
        <div className="appBar">
                <div align="center" className="appBarRow paddingTop">
                <HomeLink/>
                    <tr>
                    <td>
                        <Homebutton type="blue" path="/projects/" name="projects"/>
                    </td>
                    <td>
                        <Homebutton type="blue" path="/resume/" name="resume"/>
                    </td>
                    </tr>
                </div>
                </div>
        <div className='backstuff'>
                <div className='column container'>
                <h3 className='aboutme'/>
                <h3 className='aboutme'/>
                <h3 className='aboutme'/>
                <div className='ring center'>
                    <h3 className = "whitetext">about me</h3>
                </div>
                    <h3 className='aboutme quote'>"The greatest virtue of man is perhaps curiosity.” — Anatole France</h3>
                    <h3 className='aboutme'>I’ve been curious for as long as I can remember. As a toddler, I would mostly be seen on the floor amidst a collage of flashcards, learning and asking a ton of questions. As I grew, so did my love for trivia. As the captain of my middle school quiz bowl team, I studied everything under the sun. From medieval history to astronomy — and even pop music. We qualified for nationals (placing in the top 30 teams in the country).</h3>
                    <h3 className='aboutme'>Although I knew a lot, it never felt like I knew much of anything. I could name all the books by Fyodor Dostoevsky but only give you a few words about any particular one. What I really craved was a deep understanding of the world.</h3>
                    <h3 className='aboutme'>In high school,  I taught middle schoolers math during weekends and summer.  I volunteered at Special Olympics coaching kids basketball. And I tried my hands at making iPhone apps. Simple ones like a one-screen mobile game and an app to help elderly patients with their medication. Building real world products taught me how to break down problems into smaller pieces, but it just didn’t scratch my itch.</h3>
                    <h3 className='aboutme quote'>“Education is not the learning of facts, but the training of the mind to think”. — Albert Einstein</h3>
                    <h3 className='aboutme'>In college, I majored in the language of the universe; Mathematics. After all, galaxies and GPUs are essentially just applied math. But to my surprise, math was more like philosophy than physics — a universal framework for solving problems and not just a framework for modeling the world. Now, when I read up on new topics, I never feel intimidated because every field can be broken down into relations and first principles, just like math. And it’s not just physics and computing where this mindset is useful but practically everywhere — everything that perked up my curiosity when I was a kid.</h3>
                    <h3 className='aboutme quote'>“The strength of the team is each individual member. The strength of each member is the team.” — Phil Jackson</h3>
                    <h3 className='aboutme'>I have been a self learner most of my life. Though I was a part of multiple teams, my education was mostly solo. I learned the true value of collaboration through working with a 10 member team developing a game for UCSB. Although collaboration was challenging at times, I learned the importance of understanding others’ perspective and leveraging each team member's strengths. Sometimes compromising in pursuit of a shared vision.</h3>
                    <h3 className='aboutme'>As we transition into the AI age, the world needs curious and critical thinkers more than ever. I hope to hone my data science skills, so I can contribute to the field of agentic AI and steer the world into a better future.</h3>
                    <h3 className='aboutme'/>
                </div>
            </div>
            </div>
    )
}

export default About
