import React from 'react'
import { marked } from 'marked';
import { useNavigate } from 'react-router-dom'
import {useEffect} from 'react'

import './popup.css'

import socket from '../media/icons/socket.png'
import unity from '../media/icons/unity.png'
import node from '../media/icons/node.png'
import react from '../media/icons/react.png'
import swift from '../media/icons/swift.png'
import express from '../media/icons/express.png'
import csharp from '../media/icons/csharp.png'


const Popup = (props) => {

    var technologies = [csharp, unity, node, react, swift, express]

    const navigate = useNavigate();

    useEffect(() => {
        const handlePopState = () => {
            // Handle back button press
            console.log('Back button was pressed');
            // Add your custom logic here, e.g., navigate to a specific route
            // navigate('/some-route');
            props.setShown(false)
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    if(props.isShown) {
        return (
            <div className='container'>
                <div className="padleft">
                <div className="theCard cont row">
                    <div className="column popupcol col-xl">
                        <div className='row-sm row'>
                            <div className='col-lg'>
                                <button className='backbutton bb'>
                                    <h3 className="word row-sm bbtext" onClick={() =>{
                                        navigate(-1); props.setShown(false);}}>X</h3>
                                </button>
                                <img className="banner d-none d-sm-none d-md-none d-lg-inline-block" src={props.image} />
                            </div>
                            <div className='col-lg'>
                                <img className="banner" src={props.showPic} />
                            </div>
                        </div>
                        <p/>
                        <tr className="row-sm">
                            <td className=''>
                                <h1 className="popuptitle" dangerouslySetInnerHTML={{ __html: marked(props.name) }}></h1>
                            </td>
                            <td className="stack ">
                                {props.stack.map((item, i) => {
                                    return <img className="logo" src={technologies[props.stack[i]]}></img>
                                })}
                            </td>
                        </tr>
                        <div className="row-sm row buttonset">
                            <div className='col-3'>
                                <form action={props.live}>
                                    <button type="submit" className='linkbutton'>
                                        <p className='col buttText'>demo</p>
                                    </button>
                                </form>
                            </div>
                            <div className='col-3'>
                                <form action={props.code}>
                                <button type="submit" className='linkbutton'>
                                    <p className='col buttText'>code</p>
                                    </button>
                                </form>
                            </div>
                        </div>
                        <p/>
                        <div className='row-sm'>
                        <h3 className="desc" dangerouslySetInnerHTML={{ __html: marked(props.description) }}></h3>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    } else {
        return(<div/>)
    }

}

export default Popup
