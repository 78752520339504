import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import HomeLink from './Components/homelink'
import Typewriter from './Typewriter'

const Error = () => {

    return (
        <div>
            <div className="errorLink">
                <HomeLink/>
            </div>
            <div align="center" className="divvy">
                <div className="container column">
                    <p className="bigtext fade-in row-xl message"><Typewriter text={["Coming soon! (You can still say Hi)"]} delay={100} /></p>
                    <input className='inputField'/>
                </div>
            </div>
        </div>
        
    )
}

export default Error
