import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Homebutton from './Components/homebutton';
import Typewriter from './Typewriter';
import HelpButton from './Components/helpbutton';
import li from './media/icons/li.png'
import gh from './media/icons/gh.png'

function Home() {

  return (
    <div className="App">
      <div className="bigdiv">
        
        <div className="column center">

          <div className='ring'>
            <h1 className="whitetext">
              <Typewriter text={["arnav thirunagari"]} delay={100} />
            </h1>
          </div>
          <div align="center" className="homebuttonrow">
            <tr>
            <td>
                <Homebutton type="blue" path="/about/" name="about me"/>
              </td>
            <td>
              <Homebutton type="blue" path="/projects/" name="projects"/>
            </td>
            <td>
                <Homebutton type="blue" path="/resume/" name="resume"/>
              </td>
            </tr>
          </div>
          <p/>
          <div >
        <div align="center">
          <tr>
            <td>
              <div className="bottomButtonPadding"></div>
            </td>
            <td>
              <a href='https://www.linkedin.com/in/arnav-thirunagari-9a814b320/' className='backbutton'>
                <img className='smallicon' src={li}></img>
              </a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <a className='backbutton' href='http://github.com/arnav404'>
                <img className='smallicon' src={gh}></img>
              </a>
            </td>
          </tr>
          </div>
        </div>
            
        <p/>
        
        </div>
      </div>
    </div>
  );
}

export default Home;