import React from 'react'
import './Project.css'
import { useNavigate } from 'react-router-dom'

const PopupBackground = (props) => {

    const navigate = useNavigate();

    if(props.isShown) {
        return (
            <div className='backgrounddiv' onClick={() =>{navigate(-1); props.setShown(false)}}></div>
        )
    } else {
        return (
            <div/>
        );
    }
        
    
    
}

export default PopupBackground
